<footer class="" [class]="!hideBranding ? 'footer' : 'footer clearDisc'">
    <div class="container">
        <div class="row">
            <div class="leg">
                <p>
                    AARP endorses the AARP Medicare Supplement Insurance Plans, insured by UnitedHealthcare Insurance
                    Company. UnitedHealthcare Insurance Company pays royalty fees to AARP for the use of its
                    intellectual property. These fees are used for the general purposes
                    of AARP. AARP and its affiliates are not insurers. AARP does not employ or endorse agents, brokers
                    or producers.
                </p>
                <p>
                    Insured by UnitedHealthcare Insurance Company, Hartford, CT (UnitedHealthcare Insurance Company of
                    New York, Islandia, NY for New York residents.) Policy Form No. GRP 79171 GPS-1 (G-36000-4).
                </p>
                <p class="bold">
                    In some states, plans may be available to persons under age 65 who are eligible for Medicare by
                    reason of disability or End-Stage Renal Disease.
                </p>
                <p class="bold">
                    Not connected with or endorsed by the U.S. Government or the federal Medicare program.
                </p>
                <p class="bold">
                    This is a solicitation of insurance. A licensed insurance agent/producer may contact you.
                </p>
                <p class="bold">
                    You must be an AARP member to enroll in an AARP Medicare Supplement Insurance Plan.
                </p>
                <p>
                    THESE PLANS HAVE ELIGIBILITY REQUIREMENTS, EXCLUSIONS AND LIMITATIONS. FOR COSTS AND COMPLETE
                    DETAILS (INCLUDING OUTLINES OF COVERAGE), CALL A LICENSED INSURANCE AGENT/PRODUCER AT
                    1-866-296-9489.
                </p>
                <p>
                    <a href="https://www.aarpmedicaresupplement.com/privacypolicy.html" target="_blank">Privacy Policy
                        <svg viewBox="0, 0, 24, 24" aria-hidden="true" focusable="false" role="presentation"
                            shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#272727"
                                d="M20.09 1.99H5.84v2.79H16.5L3.03 20.17l2.1 1.84L18.17 7.1v9.87h2.79V1.99z"></path>
                        </svg></a> |
                    <a href="https://www.aarpmedicaresupplement.com/termsofuse.html" target="_blank">Terms of Use<svg
                            viewBox="0, 0, 24, 24" aria-hidden="true" focusable="false" role="presentation"
                            shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#272727"
                                d="M20.09 1.99H5.84v2.79H16.5L3.03 20.17l2.1 1.84L18.17 7.1v9.87h2.79V1.99z"></path>
                        </svg></a> |
                    <a href="https://www.uhc.com/legal/accessibility.html" target="_blank">Accessibility<svg
                            viewBox="0, 0, 24, 24" focusable="false" aria-hidden="true" role="presentation"
                            shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#272727"
                                d="M20.09 1.99H5.84v2.79H16.5L3.03 20.17l2.1 1.84L18.17 7.1v9.87h2.79V1.99z"></path>
                        </svg></a>
                </p>
            </div>
            <p class="approval-code">
                <span id="componentCode">{{componentCode}}</span>
            </p>
            <p class="copyRight">&copy; 2023 United HealthCare Services, Inc. All rights reserved.</p>
        </div>
    </div>
</footer>